.abc {
    & li {
        margin-bottom: 0.5rem;
        font-weight: 500;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}