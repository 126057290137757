@import "src/modules/Core/styles/variables.scss";

.card {
    max-height: 36vh;
    & > div > div {
        overflow: scroll;
    }
}

.feedItem {
    height: 3rem;
    margin: 0.5rem 1rem;
    border: 1px solid $grey10;
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.1rem 0.1rem $grey10;
    h2 {
        font-size: 1rem;
    }
}