@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/mixins.scss";

.form {
  & > div {
    min-height: 18vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & button {
      align-self: flex-end;
      margin: 1rem;
    }
    h2 {
      display: none;
    }
    & > div {
      margin-top: 7%;
      margin-bottom: 3%;
      width: 50%;
      align-self: center
    }
  }
  @extend .height-fill-available;
}


.card {
  min-height: unset;
  & > div:last-child {
    height: 100%;
  }
}