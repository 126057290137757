@import "src/modules/Core/styles/variables.scss";

.card {
    width: 72vw;
    min-height: 36vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 0.05rem solid $grey36;
    & > div:first-child {
        height: 5rem;
        align-items: center;
        margin-top: -0.5rem;
        margin-left: -0.5rem;
        & > h2 {
            font-size: 2.5rem;
            margin-left: 1rem;
            height: fit-content;
            overflow: visible;
            vertical-align: middle;
        }
    }

    & > div:last-child {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .label {
        color: $grey72;
        font-size: 1.5rem;
        margin-left: 1.5rem;
        margin-top: 1rem;
        font-weight: 600;
    }
}


.back {
    top: 7rem;
    top: 1rem;
    left: 1rem;
    position: absolute;
}