@import "src/modules/Core/styles/variables.scss";

.container {
  &.switch {
    padding-bottom: unset;
    p {
      width: fit-content;
    }
    & > div {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    & > div > div > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & > div > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.card {
  padding: 0rem 1rem 1.25rem 1rem;
  margin: 1.5rem 0.5rem;
  transition: 1.5s all;
  p {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    transition: 1.5s all;
  }
  box-shadow:
    0px 0.5rem 0.5rem $grey18,
    0px 0.25rem 0.5rem $grey18;
  border-color: $grey10;

  &.highlightable {
    &:has(input:focus) {
      border-color: $ocean_blue72;
      box-shadow:
        0px 0.1rem 0.25rem $ocean_blue,
        0px 0.25rem 0.25rem $ocean_blue;
      & p {
        color: $ocean_blue;
      }
    }

    &:has(textarea:focus) {
      border-color: $ocean_blue72;
      box-shadow:
        0px 0.1rem 0.25rem $ocean_blue,
        0px 0.25rem 0.25rem $ocean_blue;
      & p {
        color: $ocean_blue;
      }
    }
  }
}

.imageSchemaFormField {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & p {
    margin-bottom: unset;
  }
  & > div {
    margin: 1.25rem 1rem 0rem 0rem;
  }
  & button {
    position: absolute;
  }
}

.imagesInput {
  border: 1px dashed $grey72 !important;
}

.imagesInputInnerContainer {
  & > * {
    margin: 0rem 0.5rem;
    border-radius: 1.5rem;
  }
}
