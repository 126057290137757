@import "src/modules/Core/styles/variables.scss";

.container {
    border: 1px solid $grey5;
    border-radius: 1rem;
    margin: 1rem 0.18rem;
    height: fit-content;
    max-height: 7rem;
    overflow-y: scroll;
    box-shadow: 0px 10px 5px $grey10, 0px -3px 5px $grey10;
    padding: 0.5rem;
    min-height: 2rem;
    position: relative;
    cursor: unset;
    & > div {
        max-height:unset;
        height: -webkit-fill-available;
        & > .text {
            
            & p {
                font-size: 0.9rem;
            }
            & button {
                font-size: 0.72rem;
            }
        }
    }
}

