.card {
    min-height: 10rem;
    & > div:first-child{
        box-shadow: none;
        border: none;
    }
    & button {
        display: flex;
        flex-direction: column;
    }
}