.feature {
    top: 0rem;
    width: 100%;
    left: 0rem;
    height: 100%;
    h2 {
      text-align: center;
      max-width: 75%;
    }
    p {
      font-size: 1.25rem;
      max-width: 75%;
    }
  }