@import "src/modules/Core/styles/variables.scss";

.unauthenticatedContainer {
  & > p {
    margin: 0rem 1rem;
    font-size: 1.5rem;
  }
  & > div {
    border: 1px solid $grey50;
    height: 1rem;
  }
}
