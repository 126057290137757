
.selectItem {
    max-height: 3rem;
    & img {
      height: 3rem;
      width: 3rem;
      margin-right: 1rem;
      border-radius: 100%;
    }
    & h2 {
        font-size: 1.125rem;
        font-weight: 600;
    }
  }

  .form {
    & > div:last-child{
      left: 0rem;
      position: absolute;
      z-index: 100;
    }
  }