@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/classes.scss";

.container {
    display: flex;   
    justify-content: center;
    align-items: center;
    
    & > div:first-child {
        position: absolute;
        top: 0rem;
    }
    & .content {
        padding: 3rem;
        max-height: 90vh;
        overflow: scroll;
        width: 100%;
        @extend .height-fill-available;
        @include mobile {
            padding: 1rem 0.5rem;
        }
        & > h2 {
            margin: 1rem;
            margin-left: 1rem;
            margin-top: 3rem;
            color: $ocean_blue;
            text-decoration: underline;
        }
    }
}