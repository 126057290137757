@import "src/modules/Core/styles/variables.scss";

@keyframes open {
  0% {
    height: 6rem;
    padding: 0rem;
    width: 54vw;
  }
  100% {
    height: 54vh;
    padding: 1rem; 
    width: 72vw;
  }
} 

.container {
  // COULDO: Make trasitions generic. 
  animation: open 0.5s ease-in-out;
  animation-fill-mode: forwards;
  height: 0rem;
  width: 72vw;
  align-self: center;
  border-radius: 1rem;
  border: 1px solid $grey18;
  overflow: hidden;
  position: relative;
  overflow-x: hidden;
}
