.container {
  .feedItem {
    padding: 0rem 1rem;
    max-height: 12rem;
    & > div:first-child {
        h2 {
            font-size: 1.5rem;
            margin: 0.9rem 0rem;
        }
    }
    & > div:nth-child(2) {
        & > div {
            width: 100%;
        }

    }
  }
}
