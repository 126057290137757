@import "src/modules/Core/styles/variables.scss";

.container {
    padding: 1.5rem;
    & > div:first-child {
        &:not(:last-child) {
            border-bottom: unset;
        }
    }
    h2 {
        margin: unset;
    }
    p {
        color: $ocean_blue;
        font-size: 1.5rem;
    }

    .card {
        border-radius: 1rem;
        cursor: pointer;
    }

}