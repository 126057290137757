@import "src/modules/Core/styles/variables.scss";
.container {
    align-items: flex-start;
    & > p {
        margin: 1rem;
        color: $grey72;
        font-weight: 600;
        font-size: 2rem;
        text-align: left;
        & > button {
            text-decoration: underline;
            color: inherit;
            font-weight: 600;
            font-size: inherit;
            text-align: left;
            font-family: inherit;
            padding: unset;
            margin-left: 0.5rem;
            &:hover {
                color: $ocean_blue;
                background-color: unset;
            }
        }
    }
}

.nameField {
    padding: unset;
    &  > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: unset;
        & > div {
            min-width: calc(50% - 3rem);
            padding: 0rem 0.5rem;
        }
    }
}