.container {
  overflow: hidden;
  display: flex;
  align-items: center;
  img {
    height: 7.2rem;
    width: 7.2rem;
    margin-right: 1.8rem;
  }
}
