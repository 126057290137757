@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";

.container {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow-y: hidden;
}

.back {
  z-index: 1;
  padding: 1.5rem;
}

.forward {
  padding: 1.5rem;
  z-index: 1;
}