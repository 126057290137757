@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/classes.scss";

.container {

  & h2 {
    font-size: 1.8rem;
    margin-left: 1rem;
    @extend .singleLineText;
    @include mobile {
      font-size: 1.5rem;
    }
  }
  & > div {
    border: 3px solid $grey36;
    border-radius: 1rem;
    height: -webkit-fill-available;
    width: 90%;
    height: 90%;
  }
  .header {
    height: 10%;
  }
  
  .text {
    & p {
      font-size: 1.2rem;
      white-space: pre-line;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0rem 0.36rem;
    }
    & a {
        width: 100%;
        line-break: anywhere;
    }
    margin-left: 1rem;
    min-height: 7rem;
    height: 72%;
    overflow-y: scroll;
  }

  .footer {
    height: 7%;
  }
  .attachmentsContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    overflow-x: auto;
    align-items: center;
    & > * {
      height: 7rem;
      min-width: 7rem;
      max-width: 12rem;
      border-radius: 1.5rem;
      margin: 0rem 0.5rem 0.5rem;
    }
  }
}
