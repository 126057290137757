@import "src/modules/Core/styles/variables.scss";

.card {
  border: 1px solid $grey10;
  box-shadow:
    0px 7px 5px $grey18,
    0px -3px 5px $grey18;
  margin: 1.5rem 1rem;
  width: -webkit-fill-available;
  background-color: #fff;
  height: 10rem;
  & .id {
    height: 100%;
    h2 {
      margin: 0rem;
      font-size: 1.5rem;
      margin-top: 0.5rem;
    }
    & img {
      margin: 1.5rem;
      min-height: 6rem;
      min-width: 6rem;
    }
    & > div:last-child {
        height: -webkit-fill-available;
        justify-content: flex-start;
    }
    & .info {
      & > p:first-child {
        font-size: 1.125rem;
      }
      & > p:last-child {
        line-height: 1rem;
        margin-top: 0.5rem;
        color: $grey;
      }
    }
  }
  & h2 {
    color: $ocean_blue;
  }
}
