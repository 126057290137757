@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/mixins.scss";

.aims {
  max-height: 10rem;
  overflow-y: auto;
  @include mobile {
    padding: 0rem;
  }
  & > span {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    background-color: $grey10;
    color: #3C3B3B;
    padding: 0.36rem 0.72rem;
  }
}

.aim {
  min-height: 3rem;;
  &  * {
    transition: 1s all;
  }
  &:not(.open) {
    & > div:first-child {
      display: flex;
      align-items: center;
      & h4 {
        max-height: 3.6rem;
        height: fit-content;
        margin: 0.18rem;
        &:not(.open){
          @include multilineText(3);
        }
      }
    }
  }

  &.open {
    min-height: 7rem;
    & > div:first-child {
      & h4 {
        height: 0.5rem;
        font-size: 0.72rem;
        margin-bottom: 0.36rem;
        margin-left: 0.25rem;
        text-decoration: underline;
        @extend .singleLineText;
        min-height: 1.2rem;
        @include mobile {
          margin-bottom: 0.18rem
        }
      }
      height: 1rem;
      @include mobile {
        height: 1.2rem;
      }
    }
    & > div:last-child {
      margin-top: 0.25rem;
      border-top: none;
      @extend .height-fill-available;
      overflow: scroll;
      & p {
        height: 100%;
        max-height: unset;
      }
    }
  }
}
