@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";

.container {
  background-color: #fff;
  border: none;
  box-shadow: 0.75rem 0.75rem 0.75rem $grey18;
  min-height: 4rem;
  border: 1px solid $grey10;
  min-width: 18rem;
  overflow: hidden;
  border-radius: 1.5rem;
  @include respond-to(1700px) {
    font-size: 0.9rem;
  }
  
  & > div:first-child {
    min-height: 4rem;
    margin-bottom: unset;
    & h4, h2 {
      font-size: 1.5rem;
      @include respond-to(1700px) {
        // font-size: 0.9rem;
      }
      
    }
    & button::after {
      transform: scale(2);
    }
  }
  .content {
    border-top: 1px solid $grey18;
    margin-top: 0.25rem;
  }
  .ugb {
    top: -0.75rem;
    right: 0.1rem;
    z-index: 10;
  }
}
