.container {
  .form {
    max-height: 100%;
    max-height: 72vh;
    height: fit-content;
    width: 100%;
    & > div:last-child {
      position: relative;
      box-shadow: unset;
    }
  }
}
