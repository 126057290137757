@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/classes.scss";

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(36%, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
  min-height: 100%;
}

.cardContainer {
  display: flex;
  box-shadow: 1rem 1rem 1rem 1rem $grey10;
  border-radius: 1.5rem;
  border: 1px solid $grey18;
  cursor: pointer;
  position: relative;
  height: 12rem;

  .children { 
    p {
      line-height: 1.5rem;
    }
  }

  & > div {
    height: 100%;
    & h2 {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
    &:first-child {
      justify-content: flex-start;
      align-items: flex-start;
      & > div:first-child, img {
        height: 90%;
        height: 7.2rem;
        width: 7.2rem;
        @extend .width-fill-available;
        border-radius: 1.5rem;
        margin: 1.5rem;
        border-color: $grey18;
      }
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .vision {
    color: $grey72;
    line-height: unset;
    @include multilineText(2);
  }

  .tagsContainer {
    align-items: flex-end;
    padding: 0.5rem 1.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
    position: absolute;
    left: 0rem;
    bottom: 0rem;
    width: -webkit-fill-available;
    & > div {
      margin: 0rem 0.25rem;
    }

    & span {
      line-height: none;
    }
    .tag {
      margin: unset;
      margin: 0.15rem 0.1rem;
      height: 1.5rem;
      padding: unset;
      padding: 0.18rem 0.54rem;
      font-size: 0.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      text-transform: capitalize;
      &:not(.locationTag) {
        background-color: $ocean_blue;
        color: #fff;
      }
      &.moreTag {
        margin-left: -0.5rem;
      }
    }
  }
}

.loadMore {
  grid-column-start: 1;
  grid-column-end: -1;
}
