@import "src/modules/Core/styles/classes.scss";

.container {
  @extend .width-fill-available;
  height: 5rem;
  padding: unset;

  & > div {
    &:first-child {
        height: 5rem;
        align-items: center;
        display: flex;
        max-height: unset; 
        margin-bottom: unset;
        display: flex;
        align-items: center;
        margin-top: unset;
        & h4 {
          height: 100%;
          display: flex;
          align-items: center;
        }
    }
    &:nth-child(2) {
        margin-top: 1rem;
    }
  }
  .identityCard {
    width: 100%;

    display: flex;
    align-items: center;
    & > div:first-child {
      height: 90%;
      & > div {
        height: 3.6rem;
        width: 3.6rem;
      }
    }
    img {
    }
    & h2 {
      margin: 0rem;
      margin-left: 1rem;
    }
  }
}

.internalContainer {
  min-height: 10rem;
  padding: 0.5rem 1rem;
  overflow-y: scroll;
  height: 12rem;
}

.bio {
  overflow-x: hidden;
  overflow-y: scroll;
  & p {
    white-space: inherit;
  }
}
