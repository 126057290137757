@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.receiptButtonContainer {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}

.container {
  padding: 0rem 1rem;
  & p {
    font-size: 1.75rem;
  }

  .input {
    margin-top: 1.5rem;
  }
}

.success {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 18vh;
  & > div {
  }
  & p {
    margin: 1rem 0rem;
    color: $grey90;
    font-size: 1.75rem;
    & button {
      font-size: 1.75rem;
      font-weight: unset;
      text-decoration: underline;
      margin: unset;
      padding: unset;
      margin-left: 0.5rem;
      padding-bottom: 0.25rem;

    }
  }
}

.card {
  min-height: 25vh;
}