@import "src/modules/Core/styles/variables.scss";

.contentContainer {
    min-width: 36vw;
    min-height: 18vh;
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    & > div {
        width: 72%;
        h2 {
            font-size: 2.5rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
    .actionsContainer {
        justify-content: space-evenly;
        margin: 1.5rem auto;

        button {
            border: 1px solid $grey50;
            padding: 0.75rem 4rem;
        }
    }
}