@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div:first-child {
        position: absolute;
        top: 0rem;
    }

    .card {
        max-width: 90%;
        max-height: 90%;
        min-height: 72%;
        padding: 1rem;
        border: none;
        box-shadow: 0px 7px 5px $grey3, 0px -3px 5px $grey3;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & h2 {
            align-self: flex-start;
            margin-left: 1rem;
            @include mobile {
                margin-top: 3rem;
            }

            
        }
        & .content {
            & > div {
                height: -webkit-fill-available;
            }
            height: 100%;
            height: -webkit-fill-available;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & p {
                font-size: 1.1rem;
                margin: 1rem;
            }
            & .link {
                font-weight: 600;
                font-size: 1.5rem;
                margin-top: 3rem;
            }
        }
    }
}