@import "src/modules/Core/styles/variables.scss";

.container {
  & > div {
    height: -webkit-fill-available;
  }
}

.donationPanel {
  height: -webkit-fill-available;
  border: unset;
  justify-content: space-around;
  align-items: flex-end;
  padding-right: 3rem;

  & > div:first-child {
    padding: 1rem;
    border-radius: 1rem;
    max-width: 18rem;
    height: fit-content;
    min-height: 18rem;
    margin-top: unset;
    & > * {
      margin: 0.5rem 0;
    }
    & button {
      margin-top: 1.8rem;
    }
  }
  & > div:last-child {
    position: relative;
    bottom: unset;
    align-self: flex-end;
    margin-bottom: 1rem;
    margin-top: unset;
    & > div > div:last-child {
      display: flex;
    }
    & p {
      font-size: 0.9rem;
    }
  }
}

.activateDonations {
  align-items: flex-start;
  margin-top: 3rem;
  & > div {
    max-width: 50%;
    padding: 1rem;
    border-radius: 1rem;
    height: fit-content;
  }
}
