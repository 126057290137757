.container {
    min-height: 0rem;
    position: relative;
    bottom: 0rem;
    width: 100%;
    justify-content: flex-end;
    height: fit-content;
    overflow: visible;
    & > div {
        width: 100%;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0rem 0.5rem;
    } 
    svg {
        height: 0.9rem;
        width: 0.9rem;
    }
}