@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.item {
  position: relative;
  margin: 0.5rem 1rem;
  border: 1px solid $grey90;
  border-color: $grey72;
  border-radius: 1.8em;
  max-width: 90%;
  transition: 0.5s border-color linear;

  & h2 {
    margin: unset;
    text-transform: capitalize;
  }
  &:hover {
    border-color: $grey;
  }
  & > div {
    @extend .height-fill-available;
    align-items: center;
    text-align: center;
    color: $grey72;
    transition: 0.5s color linear;
    &:hover {
      color: $grey;
    }
  }

  &.achieved {
    border-color: $ocean_blue;
    & > div {
      color: $ocean_blue72;
      &:hover {
        color: $ocean_blue;
      }
    }
  }

}

.feedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;
  
}