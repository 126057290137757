@import "src/modules/Core/styles/variables.scss";

.drawer {
  max-width: 72vw; 
  box-shadow: 0.1rem 0.1rem 0.1rem $grey18;
  & > div {
    max-height: 100vh;
    overflow: scroll;
  }
  .header {
    padding: 0.25rem 0.9rem;
    font-weight: 700;
    font-size: 1.5rem;
  }
}