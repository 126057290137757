@import "src/modules/Core/styles/variables.scss";

.id {
    h2 {
        margin: 0.15rem 0rem;
    }
    p {
        color: $ocean_blue;
        font-size: 1.75rem;
        
    }
    & > div:first-child {
        margin: 0.75rem 1rem;;
    }
}