@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/variables.scss";

.identityCard {
    width: 100%;
    display: flex;
    align-items: center;
    & h2 {
      margin: 0rem;
      margin-left: 1rem;
    }
  }
  .container {
    @extend .width-fill-available;
    height: 5rem;
    & > div {
      &:first-child {
          height: 5rem;
          align-items: center;
          display: flex;
          max-height: unset; 
      }
      &:nth-child(2) {
          margin-top: 1rem;
      }
    }
    .identityCard {
      width: 100%;
  
      display: flex;
      align-items: center;
      img {
      }
      & h2 {
        margin: 0rem;
        margin-left: 1rem;
      }
    }
  }

  .features { 
    & > div {
      align-items: center;
      margin: 0.5rem 0rem;
      padding: 0.5rem 0.5rem;
      
      &:not(:first-child) {
        border-top: 1px solid $grey18;
      }
      
      
      & > p, h2 {
        width: 50%;
      }
      & > p {
        text-align: right;
      }
      & > h2 {
        font-size: 1.25rem;
      }
    }
  }