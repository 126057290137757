@import "src/modules/Core/styles/variables.scss";

.container {
  overflow: hidden;
  height: -webkit-fill-available;
  
  & .header {
    border-bottom: 1px solid $grey36;
    position: absolute;
    top: 0rem;
    width: 100%;
    left: 0rem;
    z-index: unset;
  }
  .content {
    height: -webkit-fill-available;
    overflow: scroll;
    height: 72%;
  }
  & > div {
    overflow-x:hidden;
  }
  & .footer {
    width: 100%;
    left: 0rem;
    position: absolute;
    justify-content: flex-end;
    display: flex;
    & button {
      margin: 1rem;
      &:hover {
        font-style: italic;
      }
    }
  }
}

.personalisedMessage {
  border: 2px double $grey18;
  margin: 1.8rem 1rem;
  border: 1px solid $grey18;
  padding: 1rem;
  box-shadow: 0.5rem 0.5rem 0.5rem $grey36;
}
