@import "src/modules/Core/styles/variables.scss";

.container {
  min-height: 7rem;
  min-width: 18rem;
  justify-content: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  border-radius: 1rem;
  position: relative;
  & > div:first-child {
    margin-top: 1rem;
    min-height: 18rem;
    width: 90%;
    & > div {
      width: -webkit-fill-available
    }
  }
}

.partnerCard {

  & > div:first-child {
    align-items: center;
    justify-content: space-between;
    & input {
      margin: 0rem 0.5rem;
      height: 1.5rem;
    }
  }
  & p {
    color: $pond_green;
    font-weight: 700;
    font-size: 1.25rem;
  }
  .identificationCard {
    & img {
      border-radius: 0.5rem;
      border: 1px solid $grey72;
      height: 3.6rem;
      width: 3.6rem;
      margin-right: 1.2rem;
    }
    & h2 {
      font-size: 0.9rem;
    }
    align-items: flex-end;
    border: 2px double $pond_green72;
    width: fit-content;
    border-radius: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
  }
  position: absolute;
  width: fit-content;
  bottom: 3.6rem;
  right: 0.5rem;
}

.businessDonationContent {
  position: absolute;
  width: fit-content;
  bottom: 3.6rem;
  right: 0.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  & > p {
    font-size: 0.75rem;
    width: fit-content;
  }
}