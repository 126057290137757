.donationsPanel {
    box-shadow: unset;
    padding: unset;
    height: -webkit-fill-available;
    & > div:first-child {
        box-shadow: unset;
        margin-top: unset;
        border: unset;
        height: -webkit-fill-available;
        & h2 {
            display: none;
        }
    }
}
