@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";

.detailsPanel {
  border-left: 1px solid $grey10;
  @include mobile {
    background: #fff;
    position: absolute;
    z-index: 3;
    right: 0rem;
  }

  display: flex;
  justify-content: center;
  & > div {
    max-width: 90%;
    width: 90%;
  }
}

.detailsContainer {
  & > div {
    border: unset;
    border-radius: unset;
  }
}

.feedItemNameContainer {
  h3 {
    margin: unset;
    white-space: pre;
    &:not(:last-child) {
      color: $grey72;
      font-size: 0.9rem;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.feedItem {
  margin: 0.9rem;
  box-shadow: 0.36rem 0.36rem 0.36rem $grey18;
  border: 1px solid $grey10;
  border-radius: 0.9rem;
  position: relative;
  @include mobile {
    max-width: 90vw;
  }
  height: 5.44rem;
  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: unset;
    & h3 {
      font-size: 2rem;
      height: fit-content;
      line-height: 3rem;
    }
    & button {
      align-self: center;
    }
  }
  & > div:last-child {
    & > div {
      border-top: unset;
    }
  }
}

.itemHeader {
  margin: 1rem 0rem;
  padding: 0rem 1rem;
  h4 {
    font-size: 1.5rem;
    margin: unset;
  }

  img {
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  cursor: pointer;
  .approved {
    align-self: flex-end;
  }

  .headerRight {
    align-items: flex-end;
  }
}
