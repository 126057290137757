@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/common.module.scss";
@import "src/modules/Core/styles/classes.scss";

.feedItem {
  min-height: 5rem;
  &:first-child {
    margin-top: 1.5rem;
  }
  @extend .roundBorderedFeedItem;
}
