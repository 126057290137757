@import "src/modules/Core/styles/classes.scss";


.detailsPanel {
    & > div {
        max-width: 90%;
        width: 90%;
    }
}

.campaignDetails {
    @extend .height-fill-available;
    max-height: 90vh;
    overflow: scroll;
    button {
        margin: 0.5rem 0.5rem;
        padding: 2rem 0.5rem;
        padding-right: 4rem;
    }
}