@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    & > div:first-child {
        position: absolute;
        top: 0rem;
    }

    .card {
        max-width: 67vw;
        max-height: 72vh;

        padding: 1rem;
        border: 1px solid $grey10;
        box-shadow: 0px 7px 5px $grey18, 0px -3px 5px $grey18;
        background: #fff;
        @include mobile {
            max-width: unset;
            width: -webkit-fill-available;
            max-height: 72%;
            padding: 1rem 0.25rem;
            max-width: 90vw;
        }
        & h2 {
            margin-left: 1rem;
            margin-bottom: 0rem;
        }
        & .content {
            display: flex;
            flex-direction: column;
            & p {
                font-size: 1.25rem;
                margin: 1rem;
            }

            & > div {
                &:first-child{
                    max-height: 90%;
                    overflow: scroll;
                }
                &:last-child {
                    & p:last-child {
                        align-self: flex-end;
                        width: fit-content;
                        margin-right: 2rem;
                        color: $grey;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}