@import "src/modules/Core/styles/variables.scss";

.container {
  box-shadow:
    0px 1px 0.5px $grey10,
    0px -1px 0.5px $grey10;
  border: 1px solid $grey10;
  align-items: flex-start;
  padding: 0.5rem;
  min-height: 7rem;
  margin-top: 1rem;
  border-radius: 0.75rem;
  margin: 1rem;
  width: -webkit-fill-available;
  box-shadow: 0px 7px 5px $grey10, 0px -3px 5px $grey10;
  border-radius: 1rem;
  & > div:first-child {
    min-width: 7.2rem;
  }
  img {
    height: 5.4rem;
    width: 5.4rem;
    border-radius: 0.75rem;
    margin-right: unset;
    margin: 0.5rem;
  }
  h2 {
    font-size: 1.25rem;
    margin-top: 0.75rem;
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
  }
  & > div:last-child {
    margin-left: 0.5rem;
    width: -webkit-fill-available;
  }
  .bio {
    width: -webkit-fill-available;
    padding: 0rem;
    h4 {
      font-size: 1rem;
      font-weight: 400;
    }
  
    & > div {
      padding-left: 0rem;
    }
  }
}

.openContainer {
  border: 1px solid yellow;
  h4 {
    display: none;
    opacity: 0;
  }
   div > div {
    border-top: none;
  }
}