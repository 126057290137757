@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.header {
  border: 1px solid $grey7;
  box-shadow: 0.5rem 0.5rem 0.5rem $grey36;
  border-radius: 1.5rem;
  margin:1rem;
  margin-top: 2rem;
  height: 20% !important;
  min-height: 17rem;
}
