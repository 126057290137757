@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/mixins.scss";

@keyframes drop {
  0% {
    transform: translateY(0vh);
  }

  75% {
    transform: translateY(75vh);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes ascend {
  0% {
    transform: translateY(100vh);
  }
  75% {
    transform: translateY(75vh);
  }
  100% {
    transform: translateY(0vh);
  }
}
@keyframes pulsating {
  0% {
    transform: scale(1);
    background-color: $grey72;
    opacity: 0.18;
  }
  50% {
    transform: scale(1.8);
    background-color: #3c3b3b;
    opacity: 0.72;
  }
  100% {
    transform: scale(1);
    background-color: $grey90;
    opacity: 0.18;
  }
}

.node {
  position: absolute;
  background-color: #3c3b3b;

  border-radius: 100%;
  height: 1.8rem;
  width: 1.8rem;
  overflow: hidden;
  transition: 1s all ease-in;
  border: 0.1rem solid $grey;
  cursor: pointer;
  transform: translateX(-0.9rem) translateY(0.9rem);
  z-index: 2;
  overflow: visible;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &.extra-small {
    max-height: 0.5rem;
    max-width: 0.5rem;
    z-index: -1;
  }

  &.dropping {
    animation: drop 10s linear infinite;
  }

  &.ascending {
    animation: ascend 10s linear infinite;
  }

  &.pulsating {
    animation: pulsating 5s linear infinite;
  }

  &.quote {
    & .textContentContainer {
      display: flex;
      flex-direction: column-reverse;
      & h4 {
        font-size: 1rem;
        text-align: right;
        width: 100%;
      }
    }
    & .btn {
      align-self: flex-start;
      align-self: center;
      &::after {
        display: none;
      }
    }
  }

  & .elipsis {
    border-width: 0.25rem;
    border-color: $grey5;
    border-style: ridge;
    padding: 0.07rem;
    width: 120%;
    height: 150%;
    border-radius: 100%;
    position: absolute;
    opacity: 0.25;
    transform: 1s all ease-in;

    &:nth-child(2) {
      rotate: 90deg;
    }
    &:nth-child(3) {
      rotate: 180deg;
    }

    &:nth-child(4) {
      rotate: 342deg;
    }

    &:nth-child(5) {
      rotate: 216deg;
    }
  }
  & > div:first-child {
    z-index: 10;
    display: none;
    justify-content: center;
    flex-direction: column;
  }
  z-index: 1;
  &.open {
    z-index: 9999909999;
    & > div:first-child {
      display: flex;
      background-color: $grey18;
      border-radius: 100%;
      padding: 1.8rem;
      height: 90%;
      width: 90%;
      overflow: hidden;
      & p {
        font-size: 1.1rem;
        margin: unset;
        font-weight: 600;
        line-height: 1.25rem;
        @include mobile {
          font-size: 0.75rem;
        }
      }
    }

    & .elipsis {
      opacity: 0.5;
      border-color: $grey18;
      border-width: 0.18rem;
      width: 107%;
      height: 136%;
      padding: unset;
    }
    border: 0.1rem solid $grey10;
    &.medium {
      height: 18rem;
      width: 18rem;
      transform: translateX(-9.9rem) translateY(9.9rem);
    }
    &.small {
      height: 15rem;
      width: 15rem;
      transform: translateX(-8.4rem) translateY(8.4rem);
    }
    &.large {
      height: 30rem;
      width: 30rem;
      transform: translateX(-15.9rem) translateY(15.9rem);
    }
    background-color: #fff;
    h4 {
      margin: unset;
      font-weight: 800;
      font-size: 1.5rem;
      @include mobile {
        font-size: 0.9rem;
      }
    }
  }
}

.btn {
  transform: 1s all ease-in;
  color: $grey90 !important   ;
  width: fit-content;
  align-self: flex-end;
  max-width: 90%;
  display: flex;
  align-items: center;
  max-height: 1.8rem;
  &::after {
    transition: 0.5s all;
    content: "\203A";
    font-size: 1.5rem;
    color: #808080e6;
    cursor: pointer;
    padding: 0rem 0.5rem;
    display: flex;
    align-items: center;
    height: 100%;
  }
  &:hover {
    color: #3c3b3b;
    opacity: 1;
    font-weight: 700;
    background-color: unset;
  }
}
