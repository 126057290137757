@import "src/modules/Core/styles/variables.scss";

.container {
  button {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0.5rem 0rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    min-height: 3rem;
    min-width: 3rem;
    box-shadow:
      0px 7px 5px $grey10,
      0px -3px 5px $grey10;
    margin: 1rem 0rem;
    & span {
      display: flex;
      color: inherit;
      width: unset;
      height: unset;
      &::after {
        font-size: 2.5rem;
        color: inherit;
      }
    }
  }
}
