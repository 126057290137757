@import "src/modules/Core/styles/classes.scss";

.photo {
  height: 7rem;
  width: 7rem;
  border-radius: 1.5rem;
  margin: 0rem 0.5rem;
}

.actions {
  @extend .width-fill-available;
  justify-content: space-between;
}

.content {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 2rem);
}
