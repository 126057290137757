@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";

.subtitle {
    color: $grey90;
    margin: 1rem 0rem 0.5rem 0.5rem;
    @include mobile {
          font-size: 0.9rem;
      }
}

.contentContainer {
    &.bordered {
        border: 1px solid $grey36;
        border-radius: 1rem;
    }
}