.container {
  h3 {
    padding: 0rem 1rem;
  }
  .textarea {
    resize: none;
    width: -webkit-fill-available;
  }
  button {
    margin: 0.5rem 1rem;
  }
}
