@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.filterFeature {
  & h4 {
    font-weight: 600;
    margin: 0.75rem 0rem;
  }
  & button {
    border: 1px solid $grey18;
    border-radius: 1rem;
    padding: 0.25rem 1rem;
    margin: 0.125rem 0.125rem;
    overflow: hidden;
  }
  padding: 0rem 1rem;
  input,
  div {
    @extend .width-fill-available;
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 5rem;
  overflow: scroll;
  button {
    margin: 0.25rem;
    font-size: 0.75rem;
    &.selected {
      border: 1px solid $ocean_blue;
      color: $ocean_blue;
    }
  }
}
