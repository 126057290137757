@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.contentContainer {
  border: 3px solid $grey3;
  border-radius: 1rem;
  overflow: scroll;
  height: 100%;
  & > div {
    padding: 1rem;
    height: 100%;
    overflow: scroll;
    & p {
      font-size: 18px;
      max-height: 72%;
      @extend .hide-scrollbar;
    }
    & > div:last-child {
      padding-bottom: 3rem;
    }
  }
}

.descriptionSectionContainer {
  & > div {
    max-height: 18rem;
    border: 1px solid $grey3;
    border-radius: 1rem;
    overflow-x: scroll;
    & > p {
      white-space: pre-line;
    }
  }
}

.aimsContentContainer {
  border: none;
}
