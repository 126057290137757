@import "src/modules/Core/styles/variables.scss";

.partnerField {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  min-width: 10vw;
  width: 12.5vw;
  min-height: 6rem;
  max-height: 10rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    & > div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      & img {
        height: 5.4rem;
        width: 5.4rem;
        border: 1px solid $grey50;
        margin-right: 1rem;
      }
      p, h2 {
        font-size: 1.25rem;
      }
    }

    &:first-child:not(:last-child) {
      border-bottom: unset;
      width: 50%;
    }
  }
  .partnerCard {
    width: 100%;
    display: flex;
    padding: 1rem 2rem;
    position: relative;
    & h2 {
      margin: unset;
    }
  }
}

.container {
  max-height: 54vh;
  padding: 0rem 1rem;
}

.screen {
  .card {
    padding: 0rem;
  }
  div {
    .cardHeader {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0.5rem 1rem;
      margin-left: unset;
      height: 3.6rem;
      h2:first-child {
        margin-left: unset;
        font-size: 1.75rem;
      }
    }
  }
}
