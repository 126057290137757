@import "src/modules/Core/styles/variables.scss";

.container {
    display: flex;
    align-items: center;
    & > div {
        &:first-child {
            width: 100%;
            
        }
    }
    .form {
        border: 1px solid $grey90;
        border-radius: 1rem;
        overflow: hidden;
        padding: 1rem;
        margin-top: 7rem;
        width: 72vw;
    }
}
