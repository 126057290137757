.appendage {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.attachment {
  border: none !important;
}

.attachmentsValueContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0rem;
  max-width: 50%;
  overflow-x: auto;
  align-items: center;
  & > * {
    height: 7rem;
    min-width: 7rem;
    max-width: 12rem;
    border-radius: 1.5rem;
    margin: 0rem 0.5rem;
  }
}
