// @import "src/modules/Core/styles/variables.scss";

$white90: #ffffffe6;
$green50: #00800080;

$white10: #ffffff1a;
$white18: #ffffff2e;
$white50: #ffffff80;

$grey3:#80808008;
$grey5: #8080800d;
$grey7: #80808012;
$grey10: #8080801a;
$grey18: #8080802e;
$grey36: #8080805c;
$grey50: #80808080;
$grey72: #808080b8;
$grey90: #808080e6;
$grey: #808080;


$purple100: #800080;
$purple90: #800080e6;
$purple72:#800080b8;
$purple50: #80008080;
$purple36: #8000805c;
$purple18: #8000802e;

$dark_purple: #480048;
$deep_purple: #840000;

$baby_blue: #87ceeb;
$ui_blue: #1976d2;
$ui_blue90: #1976d2e6;
$ui_blue72: #1976d2b8;
$ui_blue50: #1976d280;
$pond_green: #72bbbb;
$pond_green72: #72bbbbb8;
$ocean_blue: #72abab;
$ocean_blue72: #72ababb8;
$ocean_blue50: #72abab80;
$ocean_blue36: #72abab5c;
$ocean_blue10: #73abab1a;
$kermit_green: #72ab72;

$somewhat_certain:#183672;
$just_say_ok: #721446;
$success_street: #723618;
