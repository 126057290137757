@import "src/modules/Core/styles/variables.scss";

.card {
    border: 1px solid $grey10;
    box-shadow: 0px 7px 5px $grey18, 0px -3px 5px $grey18;
    margin: 1.5rem 1rem;
    & h2 {
        color: $ocean_blue;
    }
    & p {
        color: $grey;
    }
}