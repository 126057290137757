.container {
    textarea {
        width: -webkit-fill-available;
        border-radius: 0.5rem;
        margin-top: 0.25rem;
        padding: 0.5rem;
    }
    button {
        width: fit-content;
    }
}