.row {
    & > div {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 0.5rem;
        & h2, h4 {
            margin: unset
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
    margin-top: 1rem
}

.title {
    margin: 0.5rem 0.9rem
}