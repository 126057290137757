@import "src/modules/Core/styles/mixins.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;

  & .subtitle {
    font-size: 1.25rem;
    @include mobile {
      align-self: center;
      font-size: 1.125rem;
    }
  }

  & .input {
    width: 60vw; // Set the input width.
    max-width: 500px; // Optional: Set a max-width for larger screens.
    @include mobile {
      width: -webkit-fill-available;
    }
  }

  & > div > div {
    button {
      margin-top: 1.5rem;
    }
  }

  @include mobile {
    margin: 0.5rem 1rem;
    flex: 1;
    padding: 0.72rem 0.36rem 0.9rem 0.36rem;
    width: -webkit-fill-available;
    & > div {
      width: -webkit-fill-available;
    }
  }
}
