@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.feedItem {
  border: 0.1rem solid $grey10;
  margin: 0.75rem auto;
  border-radius: 1rem;
  box-shadow: 0.25rem 0.25rem 0.25rem $grey18;
  width: 90%;
  height: fit-content;
  & > div:first-child {
    height: unset;
    padding: 0rem 0.5rem;
    & > h2 {
      margin: unset;
      margin-top: 1rem;
    }
  }

  .contentWrapper {
    height: 5rem;
    overflow: scroll;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &:last-child {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
    }
    padding: 0rem 0.5rem;
    p {
      color: grey;
    }
  }
}
