@import "src/modules/Core/styles/variables.scss";

.selectItem {
  min-height: 7rem;
  box-shadow: 1rem 1rem 1rem $grey10;
  border-radius: 0.75rem;
  width: 100%;
  margin: 0.75rem 0.5rem;
  border: 1px solid $grey7;
  padding: 0.25rem 0.5rem;
  & div:first-child {
    margin-right: 1rem;
  }
  & img {
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;
    border-radius: 100%;
  }
  & h2 {
    font-size: 1.125rem;
    font-weight: 600;
  }
}

.form {
  & > div:last-child {
    left: 0rem;
    position: absolute;
    z-index: 100;
  }
}

.menu {
  & div:global(.MuiMenu-paper) {
    max-height: 50vh;
  }
}
