@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";

.header {
    @include mobile {
        max-width: 100vw;
        overflow: hidden;
    }
}

.footerContent {
    .btn {
        margin-right: 1.2rem;
    }
}