@import "src/modules/Core/styles/variables.scss";
.name {
    text-decoration: underline;
    text-transform: capitalize;
    color: $grey90;
    margin-left: 0.7rem;
    margin-right: 0.25rem;
    cursor: default;
    transition: 0.9s all;
    &:hover {
        color: $pond_green;
    }
}