@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/mixins.scss";

.container {
  @extend .width-fill-available;
  margin-top: 3rem;
  & > div {
    max-width: 90%;
    border-top: 1px solid $grey36;
    margin: 3rem auto;
    &.title {
      border-width: 0.18rem;
      margin: 1rem auto;
      display: flex;
      justify-content: center;
      padding: 0.75rem 0rem;
      & h2 {
        font-size: 2.5rem;
      }
    }
  }
  & p,
  button {
    margin: 0.5rem 1rem;
    font-size: 1.75rem;
    width: fit-content;
    transition: 1s all;
    color: #3C3B3B;
    width: -webkit-fill-available;
    box-shadow: 0.75rem 0.75rem 0.75rem $grey10;
    border: 1px solid $grey7;

    @include mobile {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
}
