.container {
    margin-top: unset;
    height: -webkit-fill-available;
    h2 {
        font-size: 1.5rem;
    }
    & > div {
        height: 100%;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        text-align: center;
        & div {
            height: 90%;
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                font-size: 1.8rem;
            }
        }
    }
}