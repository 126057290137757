@import "src/modules/Core/styles/variables.scss";

button.btn {
    position: absolute !important;
    min-height: 3rem;
    min-width: 3rem;
    height: 3rem !important;
    width: 3rem;
    top: calc(50% - 1.5rem);
    border-radius: 100% !important;
    border: 1px solid $grey10;
    box-shadow:
      0px 7px 5px $grey10,
      0px -3px 5px $grey10;
    background-color: $white18 !important;
    span::after {
      color: inherit;
    }
    &:hover {
      background-color: $white50 !important;
    }

    &.right {
        right: 1rem;
    }

    &.left {
        left: 1rem;
        transform: rotate(180deg);
        margin-left: -5%; //temp
    }

  }
  