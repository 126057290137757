@import "src/modules/Core/styles/variables.scss";

.header {
  height: 5rem;
  box-shadow: 0rem 0.5rem 0.75rem $grey10;

  & > div > span {
    margin-left: 1rem;
  }
  & h2 {
    font-size: 2rem;
  }
  .title {
    cursor: pointer;
  }
  .emblemContainer {
    height: 5.4rem;
    // width: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & > img {
      height: 90%;
      width: 90%;
      border-radius: 100%;
      cursor: pointer;
    }
  }
}
