@import "src/modules/Core/styles/variables.scss";

.screen {
  .mainContent {
    background-color: #fff;
    & > div {
      padding: 1rem;
      margin: 1rem 3rem;
    }
  }
}
