@import "src/modules/Core/styles/variables.scss";

.feedItem {
    margin: 1rem;
    box-shadow: 0px 7px 5px $grey10, 0px -3px 5px $grey10;
    border-radius: 1rem;
    width: -webkit-fill-available;
    h2 {
        margin-bottom: unset
    }
    .stamp {
        width: 5rem;
        padding: 0.5rem 1rem;   
        text-transform: uppercase;
        p {
            font-size: 1.5rem;
            font-weight: 600;
            width: fit-content;
            height: fit-content;
        }
    }
}