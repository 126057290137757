@import "src/modules/Core/styles/variables.scss";

@keyframes horizontal-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.container {
  width: -webkit-fill-available;
  overflow-x: scroll;
  min-height: 12rem;
  animation: horizontal-scroll 18s linear infinite;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  & > h2 {
    height: 6rem;
    vertical-align: middle;
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }

  &:hover {
    animation-play-state: paused;
  }
  .bannerItem {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    margin: 0rem 1rem;
    justify-content: space-between;
    height: 6rem;
    margin-top: 1rem;
    cursor: pointer;
    border: 1px solid $grey3;
    padding: 0rem 1.2rem;
    border-radius: 1.5rem;
    box-shadow:
      0px 3px 2px $grey10,
      0px -1px 2px $grey10;
    & h2 {
      margin: 0rem;
      font-size: 1.2rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      max-height: 2.7rem;
      width: fit-content;
    }
  }
  .identificationCard img {
    max-height: 3.6rem;
    max-width: 3.6rem;
  }
}
