@import "src/modules/Core/styles/variables.scss";

.fieldsContainer {
    padding: 1rem 1rem;
    padding-bottom: 5.4rem;
}

.subheader {
    margin: 0.5rem 1rem;
    color: $grey90;
    font-size: 1.1rem;
}