@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  margin: 0.25rem 0.5rem;
  cursor: pointer;
  & .expandableCard {
    border: none;
    @extend .height-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div:first-child {
      display: flex;
      justify-content: center;
      font-size: 1.5rem;
    }
  }

  & h4 {
    transition: all 1s;
  }

  &.selected {
    border: 3px solid $ocean_blue10;
    border-radius: 0.5rem;
    & h4 {
      color: $ocean_blue72;
    }
    
  }

  &.disabled {
    opacity: 0.72;
    & h4 {
      color: $grey72;
    }

  }
}
