@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Cutive+Mono&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

@font-face {
    font-family: 'futura';
    src: url('../assets/fonts/futura/Futura\ Heavy\ font.ttf')
}
@font-face {
    font-family: 'futura-light';
    src: url('../assets/fonts/futura/futura\ medium\ bt.ttf')
}

/* internal-styles */
html {

    font-size: 18px;
    color: #3C3B3B;
}

body {
    line-height: 2rem;
    font-family: 'Cutive Mono', monospace;  
    font-family: 'Source Code Pro', monospace;
    font-family: 'Inknut Antiqua', serif;
    font-family: 'Averia Serif Libre', cursive;
    font-family: 'Courier Prime', monospace;
    font-family: 'Roboto', sans-serif;
    font-family: 'Raleway', sans-serif;
    font-family: 'Georgia';
    font-family: 'futura-light';
    color: #3C3B3B;
}

h2 {
    font-size: 1.25rem;
}

h2, h4, h3, h1 {
    font-family: 'futura-light', 'futura';
    /* font-family: 'futura'; */

}


@media only screen and (max-width: 1800px) {
    html {
        font-size: 12px;
    }

    div, p, button {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 1500px) {
    html {
        font-size: 10px;
    }
    div, p, button {
        font-size: 1.5rem;
    }
}
#root{
    min-height: 100vh;
    min-width: 100vw;
}

p, div, span {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    white-space: pre-line;
    &::-webkit-scrollbar {
        display: none;
    }
}

textarea {
    padding: 1rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 1rem;
}

p {
    line-break: pre-line;
    text-wrap: pretty;
    margin-block-start: 0rem;
    margin-block-end: 0rem;
}

.chevron-down-container {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;
    color: #808080e6;
    cursor: pointer;
    &::after{
        content: "";
        display: block;
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg);
    }
}

.chevron-up-container {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;
    color: #808080e6;
    cursor: pointer;
    &::after{
        content: "";
        display: block;
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(225deg);
    }
}


.chevron-left-container {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;
    color: #808080e6;
    cursor: pointer;
    &::after{
        content: "";
        display: block;
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(135deg);
    }
}

.chevron-right-container {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 1rem;
    height: 1rem;
    border: 2px solid transparent;
    color: #808080e6;
    cursor: pointer;
    &::after{
        content: "";
        display: block;
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(-45deg);
    }
}

.close-container {
    &::after {
        content: "\00d7";
        font-size: 1.8rem;
        color: #808080e6;
        cursor: pointer;
        transition: 0.5s all;
    }
}

/* external-styles */
.MuiButton-text.MuiButtonBase-root  {
    text-transform: unset; 
}

.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 1rem;
}

.MuiInputBase-root.Mui-focused  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #72ababb8;
}

textarea:focus {
    border-color: #72ababb8;
    outline-color: #72ababb8;
}