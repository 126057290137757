.contentContainer {
    min-height: 36vh;
    width: 90vw;  

     & > div {
        max-width: 90%;
        margin: 1.5rem auto;
        p {
            text-align: justify;
            font-size: 1.5rem;
         }  

         h2 {
            font-size: 2rem;
            text-align: center;
         }

     }
}