@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/common.module.scss";

.campaignDetailsPanel {
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    max-height: 90%;
    max-width: 90%;
    border: none;
  }
}

.footerContent {
  padding: 0rem 1.2rem;
  @include mobile {
    button {
      font-size: 0.72rem;
    }
  }
}

.nestedFeedItem {
  min-height: 5rem;
  &:first-child {
    margin-top: 1.5rem;
  }
  @extend .roundBorderedFeedItem;
}


.rp {
}
