.centered-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.singleLineText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width-fill-available {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.height-fill-available {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
}
