.container {
  padding-bottom: 1.5rem;
}
.footer {
  position: absolute;
  bottom: -1rem;
  right: 0rem;
  button {
    font-size: 1rem;
    padding: unset;
    align-items: center;
    line-height: 1.75rem;
    span {
      margin-left: 0.25rem;
    }
  }
}
