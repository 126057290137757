.container {
    height: 25.2rem;
    background-color: #fff;
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & h2 {
        font-size: 1.125rem;
    }

    button {
        padding: 0.1rem;
        padding-left: 0.25rem;
    }
}
