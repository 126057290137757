.container {
  height: 100vh;
}

.footer,
.header {
  max-width: 100%;
  padding: unset;
  position: relative;
  & > *,
  & > h2 {
    margin: 0rem 2rem;
  }
  height: 5%;
}

.content {
  align-items: center;
  height: 90%;
  width: 90%;
  margin: auto;
  h2 {
    margin-right: 0.5rem;
  }
  & .row {
    & > div:last-child {
      width: fit-content;
    }
    & p {
      margin: 1.5rem 1.5rem;
    }
  }
}
