.photo {
    height: 7.5rem;
    width: 7.5rem;
    margin: 1.5rem;
}

.header {
    h2 {
        margin: 0.5rem 0rem;
    }
    padding: 0rem 1rem;
}