@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.paymentCard {
  box-shadow: unset;
  border: unset;
  p {
    font-size: 1.75rem;
  }
  input {
    max-width: 75%;
  }
  height: 25vh;
  align-items: center;
  display: flex;

  & > div {
    height: 100%;
    padding: unset;
    & > div {
      height: 100%;
      padding: unset;
    }
  }
  .amountContainer {
    justify-content: space-between;
    & > div:first-child {
      & > div {
        max-width: 75%;
        margin: auto;
        min-width: 50%;
        margin-top: 5.4rem;
      }
    }
  }
}

.paymentContent {
  & > div {
    & > *:first-child {
      margin-top: 3rem;
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}