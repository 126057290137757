@import "src/modules/Core/styles/variables.scss";


.container {
  height: 4rem;
  height: 100%;
  min-width: 30rem;
  margin-right: 1rem;
  & > .authenticatedOptions.open {
    height: fit-content;
  }
}

.authenticatedOptions {
  display: flex;
  padding: unset;
  border: unset;
  flex-direction: column;
  position: absolute;
  top: 0rem;
  right: 0rem;
  min-width: 12rem;
  min-height: 100%;
  width: unset;
  &.open {
    height: fit-content;
  }
  &:has(div > .authenticatedOptionItems) {
    box-shadow: 1rem 1rem 1rem $grey72;
  }
  & div.authenticatedOptionsWrapper {
    max-height: unset;
  }

  h4 > div {
    & > div > div {
      height: 3.6rem;
      width: 3.6rem;
    }
  }
  & > div {
    margin: unset;
    border: unset;
    & h2 {
      font-size: 1.5rem;
    }
    &:first-child {
      min-height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: unset;
    }
    &:last-child {
      background-color: #fff;
      height: fit-content;
      padding: unset;


      & > div {
        border: none;
        border-top: none;
      }
    }
    .authenticatedOptionItems {
      & button {
        font-size: 1.75rem;
        text-align: center;
        width: 100%;
        color: #3C3B3B;
        cursor: pointer;
      }
      padding: 0.5rem 0rem;
      border: 1px solid $grey10;
    }
  }
  & div > img {
    height: 3rem;
    width: 3rem;
  }
}

.unauthenticatedContainer {
  & > p {
    margin: 0rem 1rem;
    font-size: 1.5rem;
  }
  & > div {
    border: 1px solid $grey50;
    height: 1rem;
  }
}
