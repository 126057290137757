@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";

.container {
    @extend .width-fill-available;
    justify-content: space-between;
    padding: 0 1rem;
    & > div {
        flex: 1;
        & > div {
            margin: 0rem 0.5rem;
        }

        &:last-child {
            justify-content: flex-end;
        }
        & svg {
            height: 1.5rem;
            width: 1.2rem;
        }
    }
    & > div {
        width: 3rem;
        p {
            color: $grey90;
            font-size: 0.72rem;
            align-self: flex-end;
        }
    }
}