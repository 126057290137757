@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/mixins.scss";


.headerContent {
  padding: 0.5rem 1rem;
  display: flex;
  @extend .height-fill-available;
  align-items: center;
  min-height: 12rem;
  h2 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    @include respond-to(1540px){
      font-size: 2rem;
      margin: 0.75rem 0rem;
    }
  }
  p {
    color: $grey90;
    @include multilineText(3);
  }
  & > div {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &:last-child {
      align-items: center;

      h2 {
        font-size: 1.5rem;
        margin: unset;
        line-height: unset;
      }
    }
  }
}
