@import "src/modules/Core/styles/classes.scss";

.titheSwitch {
  @extend .width-fill-available;
    & p {
      font-size: 0.9rem;
      max-width: 72%;
    }
    flex-direction: row;
    align-items: flex-end;
    position: absolute;
    left: 0rem;
    bottom: 1rem; 
    justify-content: space-between;
    padding: 0rem 1rem;
  }