@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/classes.scss";

.container {
  height: 6rem;
  margin: 0rem 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  transition: 1s all;
  overflow: hidden;
  position: relative;
  &.open {
    height: 100%;
    background-color: #fff;
    width: 100%;
    @include mobile {
      margin: unset;
      margin-bottom: unset;
      justify-content: center;
      align-items: center;
    }
    
    & .formContainer {
      padding: 1rem;
      border: 1px solid $grey36;
      border-radius: 1rem;
      width: 90%;
      max-width: 90%;
      @extend .height-fill-available;
      & input,
      textarea,
      div:has(> input) {
        border-radius: 0.5rem;
        margin-top: 0.25rem;
        padding: 0.5rem;

      }
      textarea {
        height: 10rem;
        margin-top: 1.2rem;
        max-width: 100%;
      }
      & > div {
        &:last-child {
          padding-bottom: 2.5rem;
          height: 100%;
        }
      }
      & > div > div {
        &:first-child {
          overflow-y: visible;
          min-height: 50%;
        }
        &:last-child {
          position: absolute;
          bottom: 0rem;
          right: 0rem;
          width: fit-content;
          height: 2rem;
          background-color: #fff;
          margin-bottom: 1.8rem;
        }
      }
    }
  }
  & > div > button {
    display: flex;
    align-items: center;
    height: 7rem;
    width: 100%;
  }
}
.formContainer {
  overflow: hidden;
  height: 0rem;
  width: unset;
  & > div.formHeader {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    overflow: hidden;
    align-items: center;
    & > span {
      margin-right: 0.9rem;
    }
  }

  & > div:last-child {
    position: relative;
    box-shadow: unset;
  }
}
