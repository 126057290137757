.members {
    display: flex;
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    & > div {
        margin: 0rem 0.5rem;

    }
}