.container {
  min-height: 10rem;
  & > div {
    border-width: 0rem;
    border-color: transparent !important;
    height: unset;
    & > div {
      border-width: 0rem;
      border-color: transparent !important;
    }
  }
}
