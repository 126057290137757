@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/classes.scss";

.container {
    display: flex;   
    justify-content: center;
    align-items: flex-start;
    
    & > div:first-child {
        position: absolute;
        top: 0rem;
    }

    & .content {
        padding: 3rem;
        max-height: 90vh;
        overflow: scroll;
        @extend .width-fill-available;
        @extend .height-fill-available;
        @include mobile {
            padding: 1rem 0.5rem;
            & img {
                height: 5rem;
                width: 5rem;
                min-height: unset;
                min-width: unset;
                margin: 0.75rem;
            }
        }
        & > h2 {
            margin: 1rem;
            margin-left: 1rem;
            color: $ocean_blue;
            text-decoration: underline;
            @include mobile {
                margin-top: 3rem;
            }
        }
    }
}