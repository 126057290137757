
.container {
    position: relative;
}
.wrapper {
    padding: unset;
    & > div {
        padding: 0rem;
        padding-bottom: 0rem;
        & > div {
            border: unset;
            padding: unset;
            box-shadow: unset;
        }
    }
}

.footer {
    position: relative;
    bottom: 0rem;
    left: 0rem;
    width: -webkit-fill-available;
    display: flex;
    justify-content: flex-end;
    margin-top: 0.9rem;
    box-shadow: unset;
}