@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/common.module.scss";

.container {
  justify-content: space-between;
  height: 100%;
  position: relative;
  margin-top: 1rem;
  & > div:first-child {
    flex: 3 3;
    overflow-y: scroll;
    overflow-x: hidden;
    @include mobile {
      flex: 1 1;
    }
    max-height: calc(100% - 10rem);
  }
}

.viewPostPanel {
  background: #fff;
  height: 0%;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  right: 0rem;
  bottom: 0rem;
  transition: height 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.open {
    height: 100%;
    padding: 1rem;
  }

  & > div > div:last-child {
    position: absolute;
    box-shadow: unset;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    bottom: 1rem;
  }
  .chevron {
    margin-right: 1.8rem;
  }
}
