@import "src/modules/Core/styles/classes.scss";
.wrapper {
    padding: 0rem 1.8rem;
    margin-top: 0.5rem;
    .formContainer{
        & div {
            @extend .hide-scrollbar;
        }
        max-height: 72vh;
        & > div:last-child {
            position: fixed;
            bottom: 0rem;
        }
    }
}