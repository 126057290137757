@import "src/modules/Core/styles/variables.scss";

.disabledScreen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0rem;
  left: 0rem;
  background-color: #ffffffbf;
  z-index: 1;
}

.clear {
  margin-top: 0.5rem;
  height: 1rem;
  cursor: pointer;
  fill: $deep_purple;
}

.enable {
  fill: $green50;
  cursor: pointer;
  z-index: 2;
  position: relative;
  margin-top: 0.5rem;
}

.wrapper {
  span {
    z-index: 2;
    cursor: pointer;
    position: relative;
  }
}