.selected {
    border: 1px solid purple;
}

.item {
    cursor: pointer;
    & div {
        z-index: -1; //temp
    }
    & h4 {
        text-align: center;
    }
}