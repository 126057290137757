@import "src/modules/Core/styles/variables.scss";

.formContainer {
  // border: 1px solid $grey50;
  width: 90vw;
  align-self: center;
  height: 90vh;
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 1.2rem;
  position: relative;
  margin-bottom: 1rem;
  box-shadow:
  0px 1rem 0.5rem $grey36,
  0px 0.25rem 0.5rem $grey36;
  overflow-y: scroll;
  padding-bottom: 3.6rem;
  & input, textarea, div:has( > input) {
    border-radius: 1rem;
  }

  & > div > div > p {
    font-weight: 600;
    color: $grey90;
    margin-top: 0.5rem;
    margin-left: 0.25rem;
  }

  & > div:last-child {
    position: absolute;
    bottom: 0rem;
    left: 0rem;    
    
    box-shadow:
      0px 10px 5px $grey18,
      0px -3px 5px $grey18;
    background-color: #fff;
  }
}

.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    &:first-child {
      position: absolute;
      top: 0rem;
      left: 0rem;
    }

  }
}
.form {
  & > div {
   &:last-child{
    left: 0rem;
    position: relative;
   } 
  }
}
.card {
  width: 54vw;
  height: 75vh;
  box-shadow: 3rem 3rem 3rem $grey36;
  border: 1px solid $grey7;
}


.partnerWidget {
  display: absolute;
  position: absolute;
  right: 7rem;
  bottom: 7rem;
  min-width: 7rem;
  max-width: 18rem;
  min-height: 18rem;
  & > div {
    display: flex;
    flex-direction: column;
  }
}