@import "src/modules/Core/styles/classes.scss";

.base {
  @extend .singleLineText;
  font-size: 2rem;
  &::after {
    content: "\2713";
    margin: 0 0.5rem;
    font-size: 2rem;
  }
}
.founder {
  @extend .base;

}
.joined {
  
  @extend .singleLineText;
  transition: 1s all;
  overflow: hidden;
  width: 7rem;
  color: #3C3B3B;
  &:not(:hover) {
    @extend .base;
  }

  & > span {
    display: none;
  }

  &::before {
    content: "Member";
  }
  // &:hover {
  //   width: 12rem;
  //   &::before {
  //     content: "Leave Campaign";
  //   }
  // }
}
