@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/common.module.scss";
@import "src/modules/Core/styles/classes.scss";


.container {
  & > div {
  }
  .feedItem {
    height: 18rem;
    padding: 0rem 1.5rem;
    min-height: 12rem;
    @extend .roundBorderedFeedItem;

    h2 {
      font-size: 2rem;
    }
    & > div:nth-child(2) {
      max-height: 12rem;
      text-overflow: ellipsis;
    }
  }
}

.postBodyContainer {
  & > div {
    width: 50%;
  }
  height: 10rem;

  .attachmentsContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    align-items: center;
    justify-content: flex-end;
    max-height: 100%;
    & > * {
      max-width: 100%;
      max-width: 50%;
      min-height: 50%;
      max-height: 90%;
      border-radius: 1rem;
    }
  }
}

.footer {
  @extend .width-fill-available;
  position: absolute;
  box-shadow: none;
  left: 0rem;
  bottom: 0rem;
  padding: 0.5rem 1.5rem;
  max-height: 1.8rem;
  .id {
    display: flex;
    align-items: center;
    & > div {
      height: fit-content;
      display: flex;
      align-items: center;
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      border: 1px solid $grey10;
      box-shadow:
        0px 0.72rem 0.5rem $grey18,
        0px -0.5re 0.5rem $grey18;
    }
    h2 {
      font-size: 0.9rem;
      font-weight: 700;
      margin: unset;
    }
  }
  .memberId {
    max-height: 3rem;
    & > div {
      &:first-child {
        height: 2rem;
        width: 2rem;
        & > img {
          width: 2rem;
          min-width: 2rem;
          min-height: 2rem  ;
          height: 2rem;
          margin-right: 0.18rem;
          border: 1px solid $grey36; 
        }
        & > div {
          border: none;
        }
      }
    }
    h5 {
      font-weight: unset;
      margin-left: 0.25rem;
    }
  }
}
