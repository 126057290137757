@import "src/modules/Core/styles/variables.scss";
.container { 
    & > div {
        border-bottom: 1px solid $grey36;
        padding: 0rem 0.5rem;
        display: flex;
        align-items: flex-start;
        margin-top: 0.72rem;
        padding-bottom: 0.36rem;
        white-space: break-spaces;
        line-break: anywhere;
        h2 {
            font-weight: 600;
            font-size: 1rem;
            margin: unset;
            line-height: 1rem;
            margin-right: 0.5rem;
        }
        p {
            line-height: 1rem;
            font-size: 1rem;
            margin: unset;
            white-space: break-spaces;
            width: -webkit-fill-available;
            max-width: 72%;
        }
    }
}