@import "src/modules/Core/styles/variables.scss";

.card {
  border: 1px solid $grey36;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  justify-content: space-around;
  align-items: center;
  min-height: 15rem;
  width: 9rem;
  & > div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
     & img {
        min-height: 5.4rem;
        min-width: 5.4rem;
     }
  }
  .subTextContainer {
    height: 2rem;
  }

  & > div {
    width: fit-content;
  }
  h2 {
    margin: 0.1rem;
    margin: 0.25rem 0rem;
    text-align: center;
  }
  p {
    color: $grey72;
    font-size: 0.9rem;
    margin: 0.25rem 0rem;
  }
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: $grey90;
    border-radius: 100%;
    margin: 0.25rem 0rem;
  }
}
