@import "src/modules/Core/styles/variables.scss";

$height: 7rem;
.comments {
  height: 0rem;
  overflow: hidden;
  border: none;
  transition: all 0.5s ease-in-out;
  padding: unset;
  position: absolute;
  z-index: 3;
  bottom: 2.5rem;
  left: 0rem;
  & .content {
    border-top: unset;
  }
  .minimiseComment {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &.openComments {
    height: $height;
    border: 1px solid $grey50;
    background-color: #fff;
    padding: 1rem;
    & button {
      z-index: 10;
      position: absolute;
      bottom: 1rem;
      border: 1px solid grey;
    }
  }
  textarea {
    border: none;
    outline: none;
    resize: none;
  }

  button {
    font-size: 0.9rem;
    letter-spacing: 0.5;
  }
}
