@import "src/modules/Core/styles/variables.scss";

button.toggleButton {
  height: 2.5rem;
  & span {
    &::after {
      content: "+";
      font-size: 1.5rem;
      transition: all 3s;
    }
  }
  &::after {
    border: none;
  }
}

.open {
  button {
    & span {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

.customValueCard {
  & > div:first-child {
    margin-bottom: 0rem;
  }
}