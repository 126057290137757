@import "src/modules/Core/styles/variables.scss";

.container {
  position: relative;
  & > div {
    &:first-child {
      display: flex;
      align-items: flex-start;
    }
  }
}

.tabsHeaderContainer {
  margin-top: 5rem;
}

.contentContainer {
  overflow: scroll;
  & > div:first-child {
    padding: 1rem;
  }
}
.footer {
  & button {
    margin: 0rem 1rem;
  }
}
