@import "src/modules/Core/styles/variables.scss";

.roundBorderedFeedItem {
  border-radius: 1rem;
  border: 3px solid $grey10;
  padding: 0.5rem;
  max-width: 90%;
  margin-top: 0.5rem;
  box-shadow:
    0px 0.25rem 5px $grey10,
    0px -0.18rem 5px $grey10;
}
