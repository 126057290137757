@import "src/modules/Core/styles/variables.scss";

.body {
  width: fit-content;
}

.container {
  & > div:first-child {
    width: 100%;
    border-bottom: 1px solid $grey50;
  }

  & > div {
    width: fit-content;
    padding: 0rem 3rem;
    & div {
      h2 {
        width: fit-content;
        color: $grey90;
      }
    }
  }

  padding-bottom: 2rem;
}
