@import "src/modules/Core/styles/variables.scss";

.card {
  min-height: 10rem;
  & > div:first-child {
    box-shadow: none;
    border: none;
  }
  & button {
    display: flex;
    flex-direction: column;
  }
}

.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    text-align: center;
    width: 100%;
    &:first-child {
      max-height: 3rem;
      top: 0rem;
      position: absolute;
      padding: 0rem 2rem;
      h2 {
        margin-left: 2rem;
      }
    }
    & button {
      overflow: visible;
    }
    & p {
      width: 54%;
      margin: auto;
      line-height: 3rem;
      padding: 1rem 0rem;
    }
    & h2 {
      margin-left: unset;
    }
  }
}

.cards {
  padding: 0rem;
  & > div {
    width: 10rem;
    padding: 0rem 1rem;
    height: 18rem;
    & p {
      font-size: 1rem;
    }
    &:hover {
      border: 1px solid $ocean_blue72;
    }
    & button {
      overflow: visible;
      padding: 0rem;
    }
  }
}

.footer {
  height: 3rem;
  display: flex;
  align-items: center;
  & > div {
    margin-right: 1rem;
  }
  & .id {
    display: flex;
    align-items: center;
    & > div {
      height: fit-content;
      display: flex;
      align-items: center;
    }
    & h2 {
      font-size: 1rem;
      margin: none;
      margin: 0.5rem;
    }
    & img {
      height: 1.5rem;
      width: 1.5rem;
      margin: 0.5rem;
    }
  }
}
