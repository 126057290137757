@import "src/modules/Core/styles/variables.scss";

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $grey18;
    box-shadow: 0px 0px 4px 0px $grey36;
    border-radius: 1rem;
    padding: 0.9rem 1.8rem;
    position: relative;
    & img {
        height: 5.4rem;
        width: 5.4rem;
        border-radius: 1rem;
    }
     & h2 {
        margin: 0.5rem;
        font-size: 1.25rem;
     }
     & > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
     }

}