@import "src/modules/Core/styles/variables.scss";

.feedItem {
    border: 1px solid $grey36;
    width: 18rem;
    height: 17rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 7px 5px $grey18, 0px -3px 5px $grey18;
    & h2 {

        line-clamp: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .features {
        padding: 0.5rem;
        h2 {
            font-size: 1.5rem;
        }
        p {
            font-size: 1.5rem;;
        }
    }
}