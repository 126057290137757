@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/mixins.scss";

.contentContainer {
  border: 3px solid $grey36;
  border-radius: 1rem;
  overflow: scroll;
  height: 100%;
  & > div {
    padding: 1rem;
    height: 100%;
    overflow: scroll;
    & p {
      font-size: 18px;
      max-height: 72%;
      @extend .hide-scrollbar;
    }
    & > div:last-child {
      padding-bottom: 3rem;
    }
  }
}

.container {
  justify-content: space-between;
  height: 100%;
  position: relative;
  margin-top: 1rem;
  & > div:first-child {
    flex: 3 3;
    overflow-y: scroll;
    overflow-x: hidden;
    @include mobile {
      flex: 1 1;
    }
    max-height: calc(100% - 10rem);
  }
  width: 700px;
}

.descriptionSectionContainer {
  & > div {
    max-height: 18rem;
    border: 1px solid $grey36;
    border-radius: 1rem;
    overflow-x: scroll;
    & > p {
      white-space: pre-line;
    }
  }
}

.description {
  p {
    text-transform: unset;
    line-height: unset;
    padding: 0.72rem;
    white-space: pre-line;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.availibilityLabel {
  color: red;
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
}

.viewPostPanel {
  background: #fff;
  height: 20rem;
  overflow-x: hidden;
  width: 0%;
  position: absolute;
  top: 1rem;
  right: 0rem;
  bottom: 0rem;
  transition: width 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.open {
    width: 100%;
    padding: 1rem;
  }

  & > div > div:last-child {
    position: absolute;
    box-shadow: unset;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    bottom: 1rem;
  }
  .chevron {
    margin-right: 1.8rem;
  }
}

.posts {
  height: 7rem;
  overflow: hidden;
  border: none;
  transition: all 0.5s ease-in-out;
  padding: unset;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3;
  top: 2rem;
  right: 0rem;
  bottom: 0rem;
  width: 100%;
  & .content {
    border-top: unset;
    padding: 1rem;
  }

  &.openPosts {
    height: 7rem;
    border: 1px solid $grey50;
    background-color: #fff;
  }

}
