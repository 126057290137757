@import "src/modules/Core/styles/variables.scss";

.card {
    max-width: 54vw;
}

.contentContainer {
     button {
        margin-top: 1rem;
        border: 0.15rem solid $grey36;
        width: 72%;
        max-width: 30rem;
        font-size: 1.5rem;
        &.selected {
            border-color: $ocean_blue;
            color: $ocean_blue;
            font-weight: bold;
        }
     }
}