@import "src/modules/Core/styles/classes.scss";

.contentContainer {
    .form {
        max-height: 100%;
        max-height: 72vh;
        height: fit-content;
        width: 100%;
        & > div:last-child {
            position: relative;
            box-shadow: unset;

        }
    }
}

.authForm {
    & > div {
      min-height: 18vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & button {
        align-self: flex-end;
        margin: 1rem;
      }
      h2 {
        display: none;
      }
      & > div {
        margin-top: 7%;
        margin-bottom: 3%;
        width: 50%;
        align-self: center
      }
    }
    @extend .height-fill-available;
  }
  
  