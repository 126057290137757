@import "src/modules/Core/styles/mixins.scss";

.container {
    height: 100vh;
    height: 100;
}

.postContainer {
    overflow: hidden;
    min-width: 50vw;
    min-height: 50vh;
    max-width: 72vw;
    & > div {
        overflow: hidden;
        max-height: 100%;
        max-height: 72vh;
        & > div:nth-child(2) {
            max-height: 54vh;
        }
        & .postText {
            margin: 0rem 1rem;
            margin-bottom: 3.6rem;
        }
    }
    & > div > div:last-child {
        position: absolute;
        width: -webkit-fill-available;
        box-shadow: unset;
        bottom: 0;
        justify-content: center;
    }
    @include mobile {
        min-width: 90vw;
    }
}