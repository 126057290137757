@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/mixins.scss";


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div:first-child {
        position: absolute;
        top: 0rem;
    }
    @include mobile {
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 90vh;
    }

    .card {
        max-width: 90%;
        max-height: 90%;
        min-height: 72%;
        padding: 1rem;
        border: none;
        box-shadow: 0px 7px 5px $grey3, 0px -3px 5px $grey3;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
        @include mobile {
            margin-top: 5.4rem;
            max-height: 72vh;
            align-items: flex-start;
            justify-content: flex-start;
        }

        & h2 {
            align-self: flex-start;
            margin-left: 1rem;
            @include mobile {
                margin: unset;
                align-self: unset;
            }

            
        }
        & .content {
            & > div {
                height: -webkit-fill-available;
            }
            height: 100%;
            height: -webkit-fill-available;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include mobile {
                // padding-top: 54vh;
                display: block;
            }
            & p {
                font-size: 1.1rem;
                margin: 1rem;
                margin-left: 1rem;
                @include mobile {
                    font-size: 0.9rem;
                    margin: 0.5rem;
                }
            }
            & .link {
                font-weight: 600;
                font-size: 1.5rem;
                margin-top: 3rem;
                @include mobile {
                    margin-top: 1.5rem;
                }
            }
        }
    }
}