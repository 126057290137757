.form {
    width: 100%;
    max-height: 72vh;
    & > div:last-child{
        position: relative;
    }
}

.container {
    height: 72vh;
}