@import "./BudgetFeedItem/styles.module.scss";

.loadMore {
  @extend .feedItem;
  padding: unset;
}

.container {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
