@import "src/modules/Core/styles/variables.scss";

.container {
  .description {
    display: flex;
    & > p {
      font-size: 1.125rem;
      &:first-child {
        font-weight: 600;
        color: $grey90;
        transition: 0.5s all;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          color: $ocean_blue;
          opacity: 0.9;
        }
      }
    }
  }
  & > div:last-child {
    display: flex;
    align-items: center;

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      color: $grey90;
      margin: unset;
      margin-right: 1rem;
      &.accepted{
        color: $ocean_blue;
      }
      &.declined{
        color: $just_say_ok;
      }
    }
  }
}

.abc {
  & li {
      margin-bottom: 0.5rem;
      font-weight: 500;
  }
}