@import "src/modules/Core/styles/variables.scss";

.container {
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  svg {
    color: $grey90;
    fill: $grey90;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    &.active {
      color: $baby_blue;
      fill: $baby_blue;
      cursor: none;
    }
    &:hover {
      color: $ocean_blue;
      fill: $ocean_blue;
    }
  }
}
