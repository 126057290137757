@import "src/modules/Core/styles/variables.scss";

.container {
    border: 3px solid $grey10;
    display: flex;
    flex-direction: column;
    
    & > div:first-child {
        box-shadow: none;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        padding: 0rem 0.5rem;
        & h2 {
            margin-top: 1rem;
        }
        margin-bottom: 1rem;
    }

    button {
        margin-top: 0.9rem;
    }
    padding: 1.5rem;
    padding-bottom: 2rem;
}