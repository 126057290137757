@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";
@import "src/modules/Core/styles/common.module.scss";

.container {
  padding: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    max-height: 90%;
    max-width: 90%;
  }
}
