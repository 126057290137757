.container {
  display: flex;
  justify-content: flex-end;
  padding: 0rem 1rem;
  max-width: 100vw;
  align-items: center;
  padding-bottom: 9rem;
  & > div {
    &:first-child {
      width: 72%;
    }
  }
}

.footer {
  height: 7rem;
}
