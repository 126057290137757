@import "src/modules/Core/styles/classes.scss";
@import "src/modules/Core/styles/variables.scss";


.contentContainer {
    max-height: 100%;
    max-height: 72vh;
    overflow-x: scroll;
    flex-wrap: wrap;
    & > div > button {
        margin-right: 1rem;
        margin-bottom: 2rem;
    }
}

.cardHeader {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: unset;
}

.success {
  align-items: flex-start;
  min-height: 21vh;
  justify-content: center;
  & > p {
      margin: 1rem;
      color: $grey72;
      font-weight: 600;
      font-size: 2rem;
      text-align: left;
      margin-top: 2rem;
      & > button {
          text-decoration: underline;
          color: inherit;
          font-weight: 600;
          font-size: inherit;
          text-align: left;
          font-family: inherit;
          padding: unset;
          margin-left: 0.5rem;
          &:hover {
              color: $ocean_blue;
              background-color: unset;
          }
      }
  }
}

.authForm {
    & > div {
      min-height: 18vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & button {
        align-self: flex-end;
        margin: 1rem;
      }
      h2 {
        display: none;
      }
      & > div {
        margin-top: 7%;
        margin-bottom: 3%;
        width: 50%;
        align-self: center
      }
    }
    @extend .height-fill-available;
  }
  
  