@import "src/modules/Core/styles/mixins.scss";
@import "src/modules/Core/styles/variables.scss";

.donationsWidget {
  grid-row: 2/3;
}

.rp {
  & > div:last-child {
    grid-row: 5;
  }
}

.lp {
  & > div:first-child {
    grid-row: 2 / 3;
  }
}

.sidePanel {
  background-color: $grey3;
}
.mainContent {
  // box-shadow: 1rem 1rem 1rem $grey18;
  background-color: $white18;

  & > div {
  background-color: $white90;

  }
}