@import "src/modules/Core/styles/variables.scss";

.inputContainer {
  height: 18rem;
  overflow-y: scroll;
  padding: 1rem;
  & > div p {
    font-size: 0.9rem;
  }
  .formContainer {
    width: 100%;
    height: -webkit-fill-available;
    & > div:last-child {
      position: absolute;
      bottom: 0rem;
    }
  }
  & > div {
    position: relative;
    height: -webkit-fill-available;
  }
}