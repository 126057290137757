
.container {
  cursor: pointer;
  & button {
    &::before {
      content: "+";
      font-size: 1.8rem;
    }
  }
}

