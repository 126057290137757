.container {
    position: absolute;
    box-shadow: unset;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: -webkit-fill-available;
    padding: 0rem 1rem;
    bottom: 0.5rem;
    height: 1.8rem;
    background-color: transparent;
}