@import "src/modules/Core/styles/variables.scss";

.feedItem {
  margin: 1rem;
  box-shadow:
    0px 7px 5px $grey10,
    0px -3px 5px $grey10;
  width: -webkit-fill-available;
  border-color: $grey10;
  height: 5.44rem;
}
